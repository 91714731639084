html {
    user-select: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* safari hack */
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.leaflet-tile-pane {
    filter: grayscale(90%);
}

.leaflet-top, .leaflet-bottom {
    z-index: 800;
}

.div-icon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.large-label-marker {
    font-size: 40px;
    font-weight: 500;
    color: #555555;
}

.bus-marker {
    width: 100%;
    height: 100%;
}

.bus-marker-bg {
    border-radius: 0 20px 20px 20px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border: solid 2px #FFFFFF;
    font-size: 10px;
    box-shadow: 0 0 0.2em rgba(0, 45, 98, .3);
}

.bus-marker-label {
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-location-marker {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    background-color: #3a85f9;
    border: solid 2px white;
    border-radius: 100px;
    box-shadow: 0 0 0.3em rgba(0, 45, 98, .8);
}

* {
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
}